<template>
 <v-container fluid class="py-3 px-5">
   <v-row>
     <v-col cols="12" class="pb-1">
       <p class="ma-1">
        Flota
       </p>
     </v-col>
   </v-row>
   <v-row>
     <v-col cols="12" class="pt-1">
       <VehicleTable :headers="headers" :boards="boards">

       </VehicleTable>
     </v-col>
   </v-row>
 </v-container>
</template>

<script>
import VehicleTable from '@/components/VehicleTable.vue' 
export default {
  name: 'Vehicles',
  components: {
    VehicleTable
  },
  data() {
    return {
      headers: {
        vehicle: [
          {
            text: 'Codigo',
            align: 'center',
            value: 'id',
          },
          {
            text: 'Nombre vehiculo',
            align: 'center',
            value: 'name',
          },
          {
            text: 'Modelo ',
            align: 'center',
            value: 'model',
          },
          {
            text: 'Marca',
            align: 'center',
            value: 'brand',
          },
          {
            text: 'Capacidad Pax',
            align: 'center',
            value: 'capacity',
          },
          {
            text: 'Propietario Vehiculo',
            align: 'center',
            value: 'supplier_owner',
          },
          {
            text: "Acciones",
            align: 'center', 
            value: "actions"
          }
        ],
      },
      boards: {
        vehicle: 'vehicle',
      }
    }
  },
}
</script>

<style>

</style>