<template>
  <div>
    <v-row align="start">
      <v-col sm="12" md="12">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col cols="12" sm="12" md="3" >
                <h5>Flota</h5>
              </v-col>
              <v-col cols="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="vehicleSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col cols="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.vehicle)"
                >
                  Crear Vehiculo
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.vehicle"
              :items="vehicle_records"
              :items-per-page="-1"
              multi-sort
              item-key="id"
              class=" rounded-xl"
              :search="vehicleSearch"
              :loading="loadingVehicleTable"
              loading-text="Loading... Please wait"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon
                  v-if="item.id != 7" 
                  color="warning" 
                  small 
                  class="mr-2" 
                  @click="editItem(item, boards.vehicle)"
                  >
                  mdi-pencil
                </v-icon>
                <!-- <v-icon color="primary-red" small @click="openDelete(item, boards.vehicle)">
                  mdi-delete
                </v-icon> -->
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

   

    <!-- Product Form -->
    <v-row justify="center" >
      <v-form ref="createVehicleForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="vehicleDialog" persistent max-width="600px">
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Vehiculo ${createVehicleForm.name}` : `Crear Vehiculo` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col cols="12" md="12">
                    <v-text-field
                      dense
                      label="Nombre"
                      v-model="createVehicleForm.name"
                      :rules="[createFormRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      label="Modelo"
                      v-model="createVehicleForm.model"
                      :rules="[createFormRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      label="Marca"
                      v-model="createVehicleForm.brand"
                      :rules="[createFormRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                 
                </v-row>
                <v-row dense align="center">
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      label="Capacidad de Pax"
                      v-model="createVehicleForm.capacity"
                      :rules="[createFormRules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md=6>                   
                    <v-autocomplete
                      class="text-caption"
                      v-model="createVehicleForm.supplier_owner"
                      item-value="id"
                      dense
                      :items="supplierOwner_records"
                      :item-text="itemTextSupplier_owner"
                      label="Propietario"
                      required
                      clearable
                    ></v-autocomplete>
                  </v-col>
                 
                </v-row>
                
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.vehicle)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.vehicle)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.vehicle)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

   

    <!-- DIALOG DELETE PRODUCT -->
    <v-dialog v-model="dialogDeletepProduct" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar este Vehículo?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.vehicle)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.vehicle)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    
  </div>
</template>

<script>
import {axiosN506} from '../api/axiosBd'
export default {
  props:{
    headers: {
      type: Object,
      required: true
    },
    boards: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      //loadings
      isLoading: false,
      loadingVehicleTable: false,
      //dialog delete
      dialogDeletepProduct: false,
      //search
      vehicleSearch: '',
      //records
      vehicle_records: [],
      supplierOwner_records: [],
      subfamily: [],
      //form
      btnEdithVisible: false,
      valid: false,
      vehicleDialog: false,
      createVehicleForm: {},
      //form rules
      createFormRules: {
        required: value => !!value || 'El campo es requerido.',
        counter: value => value ? value.length <= 25 || 'Max 25 Caracteres': '',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      selectedRecord: null
    }
  },
  computed: {
  },
  methods: {
    async summitForm(method, board){
      this.isLoading = true
      let error = false
      let form = {}

      if (board == this.boards.vehicle) {
        if (!this.$refs.createVehicleForm.validate()) {
          error = true
        } else {
          form = this.createVehicleForm
        }
      }

     

      if (!error) {
        await this.$store.dispatch('verifyToken')

        if (method == 'POST') {
          axiosN506(`${board}/add`, {
            method: "POST",
            data: form,
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false          
          })
        } else if (method == 'PUT') {
          axiosN506(`${board}/update`, {
            method: "PUT",
            data: { id: this.selectedRecord, ...form },
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false          
          })
        }
      } else {
        //construir componente o hacer prototype para mensajes de error standar en la aplicacion
        this.$alert('Favor llenar todos los campos requeridos', 'Title', {
          confirmButtonText: 'OK',
          callback: action => {
            this.isLoading = false
          }
        })
      }
    },
    editItem(item, board){
      // this.loa
      this.btnEdithVisible = true
      this.selectedRecord = item.id
      
      if (board == this.boards.vehicle) {
        this.createVehicleForm.id = item.id
        this.createVehicleForm.name = item.name
        this.createVehicleForm.model = item.model
        this.createVehicleForm.brand = item.brand
        this.createVehicleForm.capacity = item.capacity
        this.createVehicleForm.supplier_owner = item.supplier_owner
       
        this.vehicleDialog = true
      }

      
    },
    deleteItemConfirm(board) {
      this.isLoading = true

      axiosN506(`${board}/delete`, {
        method: "DELETE",
        data: { id: this.selectedRecord },
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then(() => {
        this.isLoading = false
        this.getData(board)
        this.closeDelete(board)
      }).catch((err) => {
        console.error(err)
        alert(`error: ${err.response ? err.response : err}`)
        this.isLoading = false          
      })
    },
    openDelete(item, board) {
      if (board == this.boards.vehicle) this.dialogDeletepProduct = true

      this.selectedRecord = item.id
    },
    openCreateForm(board) {
      if (board == this.boards.vehicle) this.vehicleDialog = true
    },
    closeForm(board) {
      if (board == this.boards.vehicle) {
        this.vehicleDialog = false
        this.$refs.createVehicleForm.resetValidation()
        this.createVehicleForm = {}
      }
      this.btnEdithVisible = false
      this.isLoading = false
      this.selectedRecord = null
    },
    closeDelete(board) {
      this.selectedRecord = null

      if (board == this.boards.vehicle) this.dialogDeletepProduct = false
    },
    getData(board){
      this.loadingTable = true
      this.$store.dispatch('verifyToken')
      axiosN506({
        method: 'GET',
        url: `${board}/list`,
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then((result) => {
        if (board == this.boards.vehicle){
          this.loadingVehicleTable = false
          this.vehicle_records = result.data.data
        } 
        
        if (board == 'supplier') {
          console.log(result.data.data)
          for (let item of result.data.data) {
            if (item.business_type_supplier == 4){
              this.supplierOwner_records.push(item)
            }
           
          }
        }
      }).catch((err) => {
        console.error(err)
      });
    },
    convertToMoney(x) {
      return x
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    itemTextSupplier_owner(item){
      return `${item.business_name}    ${item.first_name}`
    }
  },
  created() {
    this.getData(this.boards.vehicle)
    this.getData('supplier')
  }
}
</script>